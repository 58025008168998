.vpsb {

  .ephemeral-box-container {
    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 100px;
    position: fixed;
    top: 100px;
    width: 400px;
    z-index: -1;

    &.ontop {
      z-index: 100;
    }

    .ephemeral-box {
      background-color: #d4edda;
      border-color: #c3e6cb;
      border-width: 1px;
      border-radius: 5px;
      border-style: solid;
      padding: 20px;
      color: black;
    }
  }

}