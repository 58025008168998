$container-max-widths: (
  sm: 550px,
  md: 740px,
  lg: 970px,
  xl: 1160px
);

@import "~bootstrap/scss/bootstrap";

$vpsb-colors: (
  almost-white: #f9f9f9,
  almost-black: #14171d,
  dark-grey: #302f2f,
  medium-grey: #aaa,
  darkish-grey: #49545a,
  light-grey: #ebebeb,
  bright-blue: #4fbff3,
  main-blue: #2580a2,
  extra-light-blue: #e3f1f7,
  light-blue: #bce7f6,
  lightish-blue: #699db0,
  medium-blue: #045c7f,
  darkish-blue: #054b7d,
  dark-blue: #043b61,
  alt-dark-blue: #202946,
  extra-dark-blue: #022a3b,
  bright-light-blue: #99e0fa,
  light-orange: #f9ebcd,
  bright-orange: #faa721,
  bright-orange-darker: #ea9409,
);

// muted = soft = close to base bg color
// strong = close to base fg color
:root {
  --vpsb-background: #{map-get($vpsb-colors, almost-white)};
  --vpsb-background-inverse: #{map-get($vpsb-colors, almost-black)};
  --vpsb-background-secondary: white;
  --vpsb-background-soft: #{map-get($vpsb-colors, light-grey)};
  --vpsb-background-soft-primary: #{map-get($vpsb-colors, extra-light-blue)};
  --vpsb-background-home-wave: #e5f2f7;
  --vpsb-primary: #{map-get($vpsb-colors, main-blue)};
  --vpsb-primary-extra-muted: #{map-get($vpsb-colors, extra-light-blue)};
  --vpsb-primary-muted: #{map-get($vpsb-colors, light-blue)};
  --vpsb-primary-medium-light: #{map-get($vpsb-colors, lightish-blue)};
  --vpsb-primary-medium: #{map-get($vpsb-colors, medium-blue)};
  --vpsb-primary-medium-strong: #{map-get($vpsb-colors, darkish-blue)};
  --vpsb-primary-strong: #{map-get($vpsb-colors, dark-blue)};
  --vpsb-secondary: #ff7200;
  --vpsb-secondary-muted: #{map-get($vpsb-colors, light-orange)};
  --vpsb-secondary-light: #{map-get($vpsb-colors, light-orange)};
  --vpsb-light-gray: #{map-get($vpsb-colors, light-grey)};
  --vpsb-medium-gray: #{map-get($vpsb-colors, medium-grey)};
  --vpsb-dark-gray: #{map-get($vpsb-colors, dark-grey)};
  --vpsb-darkish-gray: #{map-get($vpsb-colors, darkish-grey)};
  --vpsb-almost-white: #{map-get($vpsb-colors, almost-white)};
  --vpsb-almost-black: #{map-get($vpsb-colors, almost-black)};
  --vpsb-green: #28a745;
  --vpsb-font-color: #{map-get($vpsb-colors, almost-black)};
  --vpsb-font-color-inverse: #{map-get($vpsb-colors, almost-white)};
  --vpsb-font-color-strong: #{map-get($vpsb-colors, dark-grey)};
  --vpsb-font-color-muted: #{map-get($vpsb-colors, medium-grey)};
  --vpsb-font-color-bright: #{map-get($vpsb-colors, bright-orange-darker)};
  --vpsb-link-color-soft-bg: #{map-get($vpsb-colors, main-blue)};
  --vpsb-link-color-strong-bg: #{map-get($vpsb-colors, bright-light-blue)};
}

$theme-dark-color-variables: (
  vpsb-background: #{map-get($vpsb-colors, almost-black)},
  vpsb-background-inverse: #{map-get($vpsb-colors, almost-white)},
  vpsb-background-secondary: #{map-get($vpsb-colors, dark-grey)},
  vpsb-background-soft: #{map-get($vpsb-colors, alt-dark-blue)},
  vpsb-background-soft-primary: #{map-get($vpsb-colors, alt-dark-blue)},
  vpsb-background-home-wave: #0a2d3a,
  vpsb-primary: #{map-get($vpsb-colors, main-blue)},
  vpsb-primary-extra-muted: #{map-get($vpsb-colors, extra-dark-blue)},
  vpsb-primary-muted: #{map-get($vpsb-colors, dark-blue)},
  vpsb-primary-medium-light: #{map-get($vpsb-colors, darkish-blue)},
  vpsb-primary-medium: #{map-get($vpsb-colors, medium-blue)},
  vpsb-primary-medium-strong: #{map-get($vpsb-colors, lightish-blue)},
  vpsb-primary-strong: #{map-get($vpsb-colors, light-blue)},
  vpsb-secondary: #f9a43b,
  vpsb-secondary-muted: #dd8111,
  vpsb-secondary-light: #{map-get($vpsb-colors, light-orange)},
  vpsb-light-gray: #{map-get($vpsb-colors, light-grey)},
  vpsb-medium-gray: #{map-get($vpsb-colors, medium-grey)},
  vpsb-dark-gray: #c9cacb,
  vpsb-darkish-gray: #{map-get($vpsb-colors, darkish-grey)},
  vpsb-almost-white: #{map-get($vpsb-colors, almost-white)},
  vpsb-almost-black: #{map-get($vpsb-colors, almost-black)},
  vpsb-green: #28a745,
  vpsb-font-color: #{map-get($vpsb-colors, almost-white)},
  vpsb-font-color-inverse: #{map-get($vpsb-colors, dark-grey)},
  vpsb-font-color-strong: #{map-get($vpsb-colors, light-grey)},
  vpsb-font-color-muted: #{map-get($vpsb-colors, medium-grey)},
  vpsb-font-color-bright: #{map-get($vpsb-colors, bright-orange)},
  vpsb-link-color-soft-bg: #{map-get($vpsb-colors, bright-light-blue)},
  vpsb-link-color-strong-bg: #{map-get($vpsb-colors, main-blue)},
);

@mixin theme-colors($map) {
  @each $key, $value in $map {
    --#{$key}: #{$value};
  }
}

@media (prefers-color-scheme: dark) {
  body:not(.theme-light).theme-blue {
    @include theme-colors($theme-dark-color-variables);
  }
}

html[data-theme="dark"] {
  @include theme-colors($theme-dark-color-variables);
}

html {
  font-size: 1rem;

  body {
    color: var(--vpsb-font-color);
    background-color: var(--vpsb-background);
  }
}

a {
  color: var(--vpsb-link-color-soft-bg);
  &:hover {
    color: var(--vpsb-primary);
    text-decoration: underline;
  }
}

h1 {
  color: var(--vpsb-primary);
  font-weight: 600;
}

h2 {
  color: var(--vpsb-font-color);
  font-weight: 600;
}

h3 {
  color: var(--vpsb-primary);
  font-weight: 600;
  font-size: 1.65rem;
}

h4 {
  color: var(--vpsb-font-color);
  font-weight: 600;
  margin-top: 0.8rem;
  font-size: 1.3rem;
}

h1, h2, h3 {
  margin-top: 1rem;
}

.vpsb.bs4 {

  color: var(--vpsb-font-color);

  input[type="text"], input[type="password"], input[type="email"], input[type="number"], input[type="date"], select, textarea {
    background-color: var(--vpsb-background-secondary);
    color: var(--vpsb-font-color);

    &.item-param {
      background-color: var(--vpsb-background-secondary);
    }
  }

  div.selectize-input {
    background-color: var(--vpsb-background-secondary) !important;
    color: var(--vpsb-font-color) !important;

    input, div.item {
      background-color: var(--vpsb-background-secondary) !important;
      color: var(--vpsb-font-color) !important;
    }
  }

  div.selectize-control {
    div.selectize-dropdown {
      background-color: var(--vpsb-background-secondary) !important;
      color: var(--vpsb-font-color) !important;

      div.optgroup-header {
        background-color: var(--vpsb-background-secondary) !important;
        color: var(--vpsb-primary) !important;
      }
    }

    &.multi {
      div.item {
        background-color: var(--vpsb-primary-muted) !important;
        border: 1px solid var(--vpsb-font-color-muted) !important;
      }
    }
  }

  input:not(.selectize-input *)[placeholder] {
    color: var(--vpsb-font-color);
  }

  .modal-content {
    background-color: var(--vpsb-background-secondary);
    color: var(--vpsb-font-color);

    button.close {
      color: var(--vpsb-font-color);
    }
  }

  .form-control, input.item-param {
    border: 1px solid var(--vpsb-primary-strong);
  }

  .text-small {
    font-size: 0.8rem;
  }

  .main-container {
    min-height: 100%;
    margin-bottom: -270px;
  }

  .print-container {
    max-width: 900px;
  }

  .sticky-bottom, .push {
    margin-top: 30px;
    height: 240px;
    width: 100%;
  }

  .border-medium-gray {
    border-color: var(--vpsb-medium-gray);
    box-shadow: 0 1px 2px rgba(0,0,0,.05);
  }

  .bg-primary-light {
    background-color: var(--vpsb-primary-extra-muted);
  }

  .lead {
    margin-bottom: 1rem;
  }

  .thumbnail {
    padding: 4px;
    background-color: var(--vpsb-background-secondary);
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 1rem;

    &.thumbnail-padded {
      padding: 10px;
    }

    .caption {
      padding: 7px;
      color: var(--vpsb-font-color-muted);
    }
  }

  .center-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }

  .popover-header {
    margin-top: 0px;
  }

  .img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .modal {
    .modal-title {
      margin-top: 0px;
    }
  }

  pre {
    display: block;
    padding: 12px;
    margin: 0 0 10px;
    line-height: 1.42857143;
    background-color: var(--vpsb-background-secondary);
    color: var(--vpsb-font-color);
    word-break: break-all;
    word-wrap: break-word;
    border: 1px solid #ccc;
    border-radius: 4px;

    &.padding-sm {
      padding: 0.6rem;
      margin: 0;
    }

    &.no-frame {
      border: 0;
      background-color: rgba(0, 0, 0, 0);
      padding-left: 0;
    }
  }

  label {
    font-weight: 600;
  }

  .checkbox label, .radio label, label.form-check-label {
    font-weight: 400;
  }

  small.text-muted {
    font-size: 60%;
  }

  .vpsb-primary {
    color: var(--vpsb-primary);
  }

  .breadcrumb-item.active{
    color: var(--vpsb-font-color);
  }

  .panel-default .panel-title {
    color: var(--vpsb-primary);
  }

  #vpsb-breadcrumb {
    margin-top: -9px;
    background-color: var(--vpsb-background-soft);
    width: 100%;

    ol.breadcrumb {
      margin-bottom: 0;
      padding: 0.5rem 0px;
      background-color: var(--vpsb-background-soft);
    }
  }

  .alert-success {
    a {
      color: var(--vpsb-primary);
    }
  }

  @include media-breakpoint-up(sm) {
    #vpsb-breadcrumb {
      position: fixed;
      top: 49px;
      left: 0;
      z-index: 900;
      margin-top: 0;
    }
  }
}
