.vpsb {
  table.yab-card-table {
    width: 19em;
    margin-bottom: 0px;

    th {
      background-color: var(--vpsb-primary-muted);
      font-weight: 400;
      font-size: 1em;
    }

    td {
      background-color: var(--vpsb-background-secondary);

      .metric-label {
        font-style: italic;
        word-wrap: break-word;
      }

      .metric-value {
        font-size: 1.8em;
      }
    }
  }

  .yabs-list {
    tr.yab-card-row:hover td {
      background-color: var(--vpsb-background-soft);
    }
  }

  .card-with-specs {
    max-width: 100%;

    .yab-polar-chart {
      border-radius: 5px;
      width: 12em;
      height: 13em;
    }

    .yab-card-title {
      font-size: 1.3em;
      font-weight: 600;
    }

    .yabs-specs-short {
      max-width: 25em;

      table {
        margin-bottom: 0px;

        td {
          padding: 0.25em;
        }

        tr>td:first-child {
          text-align: right;
          font-weight: 600;
        }

        tr>td:last-child {
          text-align: left;
          word-break: break-word;
          word-wrap: break-word;
        }
      }
    }

    .yab-chart-column {
      width: 20em;
    }
  }

  .yab-compare-chart-container {
    .yab-card {
      background-color: var(--vpsb-background-secondary);
      border-radius: 5px;
      padding: 5px;
      margin-bottom: 5px;
      box-shadow: 1px 1px 8px rgba(13,13,18,.14);

      .yab-card-title {
        text-align: center;
      }

      .yab-polar-chart {
        width: 12em;
        height: 13em;
      }
    }

    .yab-plan-name, .yab-hoster-name {
      overflow: hidden;
      white-space: nowrap;
      max-width: 12rem;
    }

  }

  .vpsb-verified {
    width: 9em;
    color: var(--vpsb-almost-black);
    background-color: #FFF;
    border-color: var(--vpsb-green);
    border-width: 2px;
    border-style: solid;
    border-radius: 5px;
    padding: 2px 8px;
    font-size: 0.9em;

    .vpsb-span {
      font-style: italic;
      .yabs-table & {
        display: none;
      }
    }

    .icon-span {
      color: var(--vpsb-green);
    }

    .verified-span {
      font-weight: 600;
      font-size: 1.1em;
      color: var(--vpsb-green);
    }
  }

  .yabs-table {
    .vpsb-span {
      display: none;
    }
  }
}