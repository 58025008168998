.gfm {
  table {
    border-collapse:collapse;

    background-color: var(--vpsb-background-soft);
    color: var(--vpsb-font-color-strong);
  }

  table, th, td {
    border-style:solid;
    border-width:2px;
    border-color: var(--vpsb-font-color-strong);
  }

  th, td {
    padding: 5px;
  }

  pre {
    font-size: 12px;
  }

  img {
    overflow: hidden;
    padding: 20px 20px 10px 0px;
    max-width: 100%;
  }

  h2 {
    margin-top: 30px;
  }

  ul {
    padding-left: 20px;
  }

  blockquote {
    margin: 14px 0;
    border-left: 4px solid #e5e5e5;
    padding: 0 16px;
    color: #666;
  }

  &.no-bottom-margin {
    p {
      margin-bottom: 0;
    }
  }
}

table.dates {
  th, td {
    width: 200px;
    text-align: left;
  }
}

table.plan {
  th, td {
    width: 100px;
    text-align: left;
  }
}

table.review {
  width: 100%;
  border-collapse: collapse;

  td.category_name {
    background-color: #DDD;
    width: 100%;
    font-weight: bold;
  }

  td.grade {
    padding: 5px;
    width: 30%;
    border-bottom-style: solid;
    border-bottom-width: thin;
    border-bottom-color: #DDD;
  }

  td.comment {
    padding: 10px;
    width: 65%;
    vertical-align: top;
    border-bottom-style: solid;
    border-bottom-width: thin;
    border-left-style: solid;
    border-left-width: thin;
    border-left-color: #DDD;
  }

  td.numbers {
    padding: 5px;
    width: 30%;
    border-bottom-style: solid;
    border-bottom-width: thin;
  }
}

table.reviewtrials {
  width: 500px;

  th, td {
    text-align: left;
  }
}
