.vpsb {
  table#chat-index td {
    padding: 0.25rem;
  }

  div#intelligence-corner-button-container {
    position: fixed;
    bottom: 1.3rem;
    right: 1.3rem;
    z-index: 1000;

    a {
      min-width: 0;
      width: 60px;
      height: 60px;
      background: linear-gradient(to right, #ff7e5f, #feb47b);

      svg {
        width: 48px;
        min-width: 48px;
        height: 48px;
      }
    }
  }

  div.example-stamp {
    display: flex;
    padding: 1.2rem;
    background-color: var(--vpsb-background-secondary);
    border: var(--vpsb-font-color-muted) solid 1px;
    border-radius: 5px;
    width: 30%;
    font-size: 1.2rem;
    font-weight: 600;

    a {
      text-decoration: none;
    }
  }

  div#message-list-wrapper {
    margin-bottom: 200px;
    margin-top: 2rem;

    div.help-message {
      position: relative;
      font-style: italic;
      padding: 1rem;

      .close-cross {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 20px;
        font-style: normal;
        color: var(--vpsb-font-color-muted);
        cursor: pointer;
      }
    }

    div.user-message {
      text-align: right;
      color: var(--vpsb-font-color-bright);
      font-style: italic;
      font-size: 1.2rem;
      font-weight: 600;
      padding-top: 2rem;
      padding-bottom: 1rem;
    }

    div.assistant-message,div.model-message .gfm {
      h1 {
        color: var(--vpsb-font-color);
        font-weight: 600;
        font-size: 1.5rem;
      }

      h2 {
        color: var(--vpsb-font-color);
        font-weight: 600;
        font-size: 1.3rem;
      }

      h3 {
        color: var(--vpsb-font-color);
        font-weight: 600;
        font-size: 1.2rem;
      }

      h4 {
        color: var(--vpsb-font-color);
        font-weight: 600;
        font-size: 1.1rem;
      }

      table {
        margin-bottom: 2rem;

        th,td {
          padding: 0.3rem;
        }
      }

      pre {
        background-color: white;
        color: black;
      }
    }
  }

  textarea#prompt-input {
    &.loading {
      color: black;
      background-image: linear-gradient(
        -45deg,
        #f0f0f0 25%,
        #e0e0e0 25%,
        #e0e0e0 50%,
        #f0f0f0 50%,
        #f0f0f0 75%,
        #e0e0e0 75%,
        #e0e0e0 100%
      );
      background-size: 40px 40px;
      animation: moveStripes 1s linear infinite;
    }

    @keyframes moveStripes {
      0% {
        background-position: 0 0;
      }
      100% {
        background-position: 40px 0;
      }
    }
  }

  a#prompt-button {
    font-size: 2rem;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-top: 3px;
  }

  p#llm-tokens-info-line {
    color: var(--vpsb-font-color-muted);
    font-size: 0.9rem;
    font-style: italic;
  }
}

body.vpsb {

  &.accounts_intelligence {
    div#prompt-container {
      position: fixed;
      bottom: 1.3rem;
    }

    @media (min-width: 768px) {
      div#prompt-container {
        width: calc(100% - 16.66% - 50px);
        max-width: calc(0.83 * (1160px - 50px));
      }
    }

    @media (max-width: 768px) {
      div#prompt-container {
        width: calc(100% - 30px);
      }

      a#prompt-button {
        display: none;
      }
    }

    @media (max-width: 768px) {
      div#message-list-wrapper, div.title-row {
        margin-right: 0;
      }
    }

    @media (min-width: 768px) {
      div#message-list-wrapper, div.title-row {
        margin-right: 20px;
      }
    }
  }

  &.llm_chats {
    div.uber-container {
      position: fixed;
      bottom: 0px;
      padding-left: 0;
    }


    @media (min-width: 992px) {
      // div.uber-container {
      //   width: calc(100% - 33.33% - 50px);
      //   max-width: calc(0.83 * (1160px - 50px));
      // }
    }

    @media (max-width: 992px) {
      div#prompt-container {
        // margin-right: 1rem;
      }

      div.uber-container {
        width: calc(100% - 30px);
        padding-right: 0;
      }

      a#prompt-button {
        display: none;
      }
    }
  }


}
