.vpsb  {
  .screener-form-frame {
    background-color: var(--vpsb-background-secondary);
    color: var(--vpsb-font-color);
    border-color: var(--vpsb-primary-medium);
    border-width: thin;
    border-style: solid;
    border-radius: 5px;
    padding: 20px;
  }

  @media only screen and (min-width: 992px) {
    .sticky-position {
      position: sticky;
      top: 70px;
    }
  }

  @media (max-height: 799px) {
    .hide-on-short-screen {
      display: none;
    }
  }

  .screener-slider {
      display: inline-block;
      vertical-align: middle;
      float: none !important;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 10px;
      padding-right: 0px;
  }

  .ui-slider-handle.ui-state-default {
    background-color: var(--vpsb-primary);
  }

  .narrow-hpadding {
    padding-right: 5px;
  }

  .slider-legend {
    font-size: 14px;
  }

  .instruction {
    font-size: larger;
    font-style: italic;
    padding-bottom: 10px;
  }

  .thin-orange-frame {
    background-color: var(--vpsb-primary-muted);
    border-color: var(--vpsb-primary-medium);
    border-width: thin;
    border-style: solid;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-style: italic;
  }

  .dd-low-margin dd {
    margin-bottom: 0px;
  }

  .price-weighted-subtitle {
    font-size: 10px;
    font-style: oblique;
    color: #721009;
  }
}
