.vpsb {
  .announcement-banner {
    height: 4.2rem;
    background-color: var(--vpsb-background-secondary);
    border-radius: 6px;
    border-color: var(--vpsb-medium-gray);
    border-width: 1px;
    box-shadow: 2px 2px 6px var(--vpsb-medium-gray);
    border-style: solid;
    text-align: left;
    color: var(--vpsb-secondary);
    font-size: 1.2rem;
    padding: 1rem 1em 1rem 1em;
    margin-top: 1.1rem;
    margin-bottom: 1.5rem;

    .truncate-line {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 0px;
    }

    .hide-cross {
      text-align: right;
      width: 3rem;
    }
  }

  .announcement-title {
    font-size: 1.35rem;
    font-weight: 600;

    a {
      text-decoration: none;
    }
  }

  .announcement {
    p {
      margin-bottom: 0.6rem;
    }

    h1 {
      font-size: 1.35rem !important;
      font-weight: 600;
      margin-top: 0.7rem;
    }

    h2 {
      font-size: 1.2rem !important;
      font-weight: 600;
    }

    h3 {
      font-size: 1.1rem !important;
    }

    h4 {
      font-size: 1rem !important;
    }

    &.account-announcement-frame {
      border-color: var(--vpsb-primary);
      border-width: 1px;
      border-style: solid;
      border-radius: 4px;
      padding: 7px;
      margin: 2px;
    }

    &.public-announcement-frame {
      border-width: 0px;
      box-shadow: 4px 4px 16px var(--vpsb-medium-gray);
      border-style: solid;
      border-radius: 3px;
      background-color: var(--vpsb-background-secondary);
      padding: 12px;
      margin: 2px;

      .timestamp {
        font-size: 0.9rem;
        font-weight: 400;
      }
    }
  }

  .decline-reason {
    border-radius: 3px;
    background-color: var(--vpsb-primary-muted);
    padding: 8px;
  }

  .public-announcement-body-frame {
    background-color: var(--vpsb-background);
    border-color: var(--vpsb-primary-medium-light);
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    padding: 10px 20px 10px 20px;
    font-weight: 400;
  }

  .home-announcements {
    .public-announcement-frame {
      border-radius: 5px;

      .title-wrapper {
        height: 11rem;
      }
    }

    a.no-link-decoration {
      text-decoration: none;
      color: var(--vpsb-dark-gray);
      font-weight: 500;
    }

    .announcement-title {
      font-size: 1.2rem;
      a {
        color: black;
      }
    }
  }

  div#announce-draft-body {
    background-color: white;
  }
}