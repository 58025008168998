.vpsb .sidebar {

  .sidebar-unit {
    margin-bottom: 1rem;
  }

  @include media-breakpoint-up(lg) {
    .sidebar-unit {
      margin-left: 2rem;
    }
  }

  &.theme-light {
    // background-color: var(--vpsb-primary-extra-muted);

    .card.card-sidebar {
      color: var(--vpsb-font-color);
      // box-shadow: 0 4px 2px -2px gray;
      // border-radius: 0;
    }

    .card.card-sidebar {
      background-color: var(--vpsb-background-soft-primary);
      box-shadow: 0px 4px 6px 1px  rgba(13, 13, 18, 0.08);
      color: var(--vpsb-font-color);

      .card-header {
         background-color: var(--vpsb-background-soft-primary);
        .card-title {
          color: var(--vpsb-font-color-primary);
        }
      }

      .card-body {
      }
    }
  }

  &.theme-dark {
    // background-color: var(--vpsb-dark-gray);
    color: var(--vpsb-font-color);

    .card.card-sidebar {
      background-color: var(--vpsb-background-soft-primary);
      color: var(--vpsb-font-color);

      .card-header {
        background-color: var(---vpsb-background-soft-primary);

        .card-title {
          color: var(--vpsb-font-color);
        }
      }
    }

    .vpsb-ad-unit {
      color: var(--vpsb-primary-extra-muted);
    }
  }
}