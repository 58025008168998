.vpsb.bs4 {
  .card {
    background-color: var(--vpsb-background-secondary);

    &.card-sidebar {
      border-color: var(--vpsb-medium-gray);
      border-width: 0px;
      box-shadow: 0 8px 14px rgba(13,13,18,.08);
      margin-bottom: 1.5rem;

      .card-title {
        font-size: 1.4rem;
        font-weight: 600;
        color: var(--vpsb-dark-gray);
        margin: 0px;
        text-align: center;
      }

      .card-header {
        padding: 1rem 0.5rem;
        background-color: white;
        border-bottom: 1px solid;
        border-bottom-color: var(--vpsb-primary);
      }

      .card-body.overflow-scroll {
        overflow-y: scroll;
        height: 400px;
      }
    }

    .card-header {
      background-color: var(--vpsb-background-secondary);

      h3 {
        font-size: 1.3rem;
        font-weight: bold;
      }
    }

    &.card-ad {
      background-color: var(--vpsb-background-soft-primary);
      border-color: var(--vpsb-medium-gray);
      box-shadow: 0 8px 14px rgba(13,13,18,.08);
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
  }

  .card.panel-primary-header {
    border-color: var(--vpsb-primary);

    & > .card-header {
      background-color: var(--vpsb-primary);

      background-image: -webkit-linear-gradient(top, var(--vpsb-primary-muted) 0,var(--vpsb-primary) 100%);
      background-image: -o-linear-gradient(top, var(--vpsb-primary-muted) 0,var(--vpsb-primary) 100%);
      background-image: -webkit-gradient(linear,left top,left bottom,from( var(--vpsb-primary-muted)),to(var(--vpsb-primary)));
      background-image: linear-gradient(to bottom, var(--vpsb-primary-muted) 0,var(--vpsb-primary) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='var(--vpsb-primary-muted)', endColorstr='var(--vpsb-primary)', GradientType=0);
      background-repeat: repeat-x;

      & > .card-title {
        color: #FFF;
        margin-bottom: 0px;
        font-weight: 600;
      }
    }
  }

  .well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f1f1f1;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);

    html[data-theme="dark"] & {
      color: var(--vpsb-font-color-inverse);
    }
  }
}
