// Place all the styles related to the compare_* controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/
.vpsb {

  .equal-width-buttons-column {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
  }

}