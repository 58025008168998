body.vpsb.home {

  background-color: var(--vpsb-background);
  color: var(--vpsb-font-color-strong);
  font-size: 1rem;

  h1, h2 {
    font-weight: 600;
    color: var(--vpsb-font-color);
  }

  p.pre-heading {
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--vpsb-secondary) !important;
    margin-top: 1rem;
    margin-bottom: -0.4rem;
  }

  .banner {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 13px;
    padding-right: 13px;
    margin: 0px;
  }

  .banner-light {
    background-color: var(--vpsb-background);
    color: var(--vpsb-font-color-strong);

    h1, h2, h3 {
      a {
        color: var(--vpsb-primary);
      }
    }

    h2 {
      font-size: 2rem;
    }

    h4 {
      font-size: 1.1rem;
      color: var(--vpsb-primary);
    }

    p {
      color: var(--vpsb-font-color-strong);
    }

    p a, li a {
      font-weight: 400;
    }

    .call-to-action {
      font-weight: 600;
      font-size: 1rem;
    }

  }

  .banner-headline {
    background-color: var(--vpsb-background-home-wave);
    color: var(--vpsb-font-color-strong);
    margin-top: -20px;

    h1 {
      color: var(--vpsb-font-color);
      font-size: 3rem;
    }

    .subtitle {
      font-size: 2.6rem;
      color: var(--vpsb-font-color-strong);
    }

    h3 {
      color: var(--vpsb-font-color);
    }

    p.lead {
      font-weight: 400;
      font-size: 1.1em;
    }

    .container {
      // position: relative;
      // height: 400px;
      // background: var(--vpsb-primary-extra-muted);
      // background-image: linear-gradient(155deg, #002f62 0%, #18496a 50%, #138b89 100%);
    }
  }


  .wave-container {
    svg {
      position: relative;
      bottom: 0;
      width: 100%;
      height: auto;
      background-color: var(--vpsb-background);
    }
  }

  .banner-light-grey {
    background-color: #EEE;

    h1, h2, h3 {
      a {
        color: #A00;
      }
      color: #A00;
    }

    th, td {
      padding-left: 0px;
    }
  }

  nav.vpsb-navbar {
    margin-bottom: 0px;

    &.bg-dark {
      background-color: var(--vpsb-background-home-wave) !important;
    }
  }

  section {

    p,a,ul,th {
      font-size: 1em;
    }

    .small-text {
      font-size: 0.9em;
    }

    .best-vps {

      h2 {
        font-size: 1.6em;
        color: var(--vpsb-primary);
      }

      ul {
        padding-left: 0px;
        list-style-type: none;

        li {
          line-height: 35px;
        }
      }

      .lower-rank {
        display: inline-block;
        width: 40px;
        font-weight: 600;
      }

      .bvps-frame {
        display: flex;
        flex: 1 0;
        margin-bottom: 0px;
        padding-right: 0px;
      }

      .bvps-wrapper-link {
        flex: 1 0;
        display: flex;
        text-decoration: none;
        color: #444;
      }

      .bvps-inner-row {
        display: flex;
        justify-content: space-between;
        flex: 1 0;
        border-style: solid;
        border-radius: 5px;
        border-width: 1px;
        border-color: var(--vpsb-medium-gray);
      }

      .bvps-inner-left-col {
        background-color: var(--vpsb-primary-extra-muted);
        padding: 20px;
        display: flex;
        flex: 1 0 40%;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
      }

      .bvps-inner-right-col {
        background-color: var(--vpsb-background-secondary);
        color: var(--vpsb-font-color);
        border-radius: 5px;
        padding: 15px;
      }

      .hiding-wrapper {
        height: 100%;
        display: flex;
      }

      #button-form {
        height: 100%;
      }

      .button-column {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        input[type="radio"] {
          display: none;
        }

        input[type="radio"]:checked + label {
          color: var(--vpsb-link-color-soft-bg) !important;
          background-color: var(--vpsb-primary-muted);
          border-color: var(--vpsb-primary);
          box-shadow: none;

          html[data-theme="dark"] & {
            background-color: var(--vpsb-primary-extra-muted);
          }
        }
      }

      .range-select-btn {
        font-weight: 600;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }

    .panel-shadow {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .row.feature {
      margin-top: 40px;
      margin-bottom: 20px;

      &.direction-odd {
        flex-wrap: wrap;
      }

      &.direction-even {
        flex-wrap: wrap-reverse;
      }
    }

    a.no-decoration {
      text-decoration: none;
      color: #444;
      font-weight: 500;
    }

    a.with-decoration {
      text-decoration: underline;
    }
  }
}

.plain-list {
  padding-left: 0px;
  list-style: none;
}
