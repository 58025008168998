.vpsb.bs4 {

  nav {
    padding-top: 0px;
    padding-bottom: 0px;
    border-bottom: 1px solid;
    border-color: var(--vpsb-medium-gray);
    background-color: var(--vpsb-background);

    .header-brand {
      line-height: 1.5rem;
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
      font-style: italic;
      font-size: 2.3rem;
      font-weight: 600;
      color: var(--vpsb-secondary);

      span.benchmarks-color {
        color: var(--vpsb-font-color);
      }
    }

    &.bg-dark {
      background-color: var(--vpsb-background) !important;
    }

    &.navbar-dark {
      &.vpsb-navbar .navbar-nav > li > a {
        &:hover, &:focus {
          color: #FFF;
        }
      }

      .nav-item {
        &.active {
          font-weight: 600;
          background-color: var(--vpsb-primary-extra-muted);
          color: var(--vpsb-font-color-strong);
        }

        &:hover {
          background-color: var(--vpsb-primary-extra-muted);
          color: var(--vpsb-primary) !important;
        }
      }

      .dropdown-menu {
        background-color: var(--vpsb-background);
        border-color: var(--vpsb-background-soft);
        border-width: 1px;

        .dropdown-item {
          color: var(--vpsb-font-color-strong);

          &:hover {
            background-color: var(--vpsb-background-soft);
          }
        }
      }
    }

    &.navbar-light {

      .nav-item {
        &.active {
          font-weight: 600;
          color: rgba($black, 0.9);
        }

        &>a {
          color: var(--vpsb-font-color-strong);

          &:hover {
            color: var(--vpsb-primary) !important;
          }
        }

        &:hover {
          background-color: var(--vpsb-primary-extra-muted);
          border-radius: 5px;
        }
      }

      .dropdown-item {
        color: var(--vpsb-font-color-strong);

        &:active {
          color: white;
        }
      }
    }

    .navbar-brand {
      padding-top: 7px;
      padding-bottom: 10px;
    }

    .nav-item {
      margin-right: 0.6rem;

      &:last-child {
        margin-right: 0px;
      }
    }

    &.navbar-home {
      background-color: var(--vpsb-background-home-wave);
      border-width: 0px;

      .brand-vps {
        font-weight: 600;
        font-style: oblique;
        font-size: 2rem;
        color: var(--vpsb-primary);
      }

      .brand-bench {
        font-weight: 600;
        font-style: oblique;
        font-size: 2rem;
        color: black;
      }
    }
  }

  nav.horizontal-nav {
    @include media-breakpoint-up(lg) {
      .navbar-nav {
        .nav-item:first-child {
          margin-left: -7px;
        }
      }
    }
    @include media-breakpoint-up(xl) {
      .navbar-nav {
        .nav-item:first-child {
          margin-left: 0px;
        }
      }
    }
  }

  #colorScheme.toggle-group {
    width: 150px;
    padding: 3px;
    margin: 0.5rem;

    input[type="radio"] {
      position: absolute;
      opacity: 0;
      width: 0;
      height: 0;
    }

    label {
      color: var(--vpsb-font-color-strong);
      background-color: var(--vpsb-background);
      border-radius: 5px;
      border: 1px solid;
      border-color: var(--vpsb-background-soft);
      padding: 0.5em 1em;
      text-align: center;

      &:hover {
        border-color: var(--vpsb-light-gray);
      }
    }

    label:has(input:checked) {
      background-color: var(--vpsb-primary);
      color: var(--vpsb-almost-white);
      border-color: var(--vpsb-medium-gray);
    }
  }
}