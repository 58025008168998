.vpsb {
  &.accounts_hosters,&.accounts_users,&.accounts_announcements,&.vps_instances.index,&.vps_instances.new,&.vps_instances.create,&.vps_instances.new_public,&.vps_instances.create_public,&.accounts_promotion_reviews,&.accounts_plans,&.accounts_yabs,&.accounts_cheap_vps,&.accounts_promotions,&.accounts_intelligence {
    .stretched-row-with-gap {
      display: flex;
      justify-content: space-between;
    }

    .provider-options-row {
      display: flex;
      flex-wrap: wrap;
      margin-left: -15px;
      margin-right: -15px;
      margin-bottom: 15px;

      .option-stamp {
        display: flex;
        flex-direction: column;

        border-radius: 5px;
        border: 2px solid;
        border-color: #CCC;
        background: #FFF;
        font-size: 18px;
        margin-bottom: 5px;
        margin-top: 5px;
        padding: 0 20px 20px 20px;
        min-width: 230px;

        flex: 1 0;
        margin-left: 15px;
        margin-right: 15px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .vertical-nav {
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      transition: all 0.4s;
      padding-top: 100px;

      html[data-theme="light"] & {
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
      }

      html[data-theme="dark"] & {
        box-shadow: 3px 3px 10px rgba(255, 255, 255, 0.3);
      }

      nav {
        border-width: 0;
      }

      .nav-link.active {
        font-weight: 600;
      }
    }

    @media (min-width: 1000px) {
      .vertical-nav {
        width: 12rem;
      }
    }
    @media (max-width: 1000px) {
      .vertical-nav {
        width: 8rem;
      }
    }
  }

  &.accounts_hosters,&.accounts_users {
    .btn {
      min-width: 210px;
    }
  }

  &.accounts_invoices.show.print {
    background-color: white;
    color: black;

    h1,h2,h3,h4 {
      color: black;
    }

    table.table {
      color: black;
    }

    .paid {
      font-size: 40px;
      font-weight: 600;
    }
  }

  form.promotion-form {
    .form-control:not(.wide) {
      width: 150px;
    }
  }

}