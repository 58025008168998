.vpsb.cheap_vps {
  table.cheap-vps-table {
    background-color: var(--vpsb-primary-extra-muted);

    td {
      padding: 0.3rem 0.75rem 0.3rem 0.75rem;
    }

    p.value {
      font-size: 1.3rem;
      font-weight: 600;

      &.locations {
        font-size: 1.1rem;
        font-weight: 400;
        }
    }

    p.label {
      margin-bottom: 0.35rem;
      font-style: italic;
    }
  }
}

.vpsb.coupons {
  .promotion-desc {
    h4 {
      font-size: 1.2rem;
      font-weight: 600;
      padding-left: 5px;
    }

    p {
      margin-bottom: 0.5rem;
      padding-left: 5px;
    }

    .grid-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr; /* Creates three equal width columns */
      grid-template-rows: repeat(4, minmax(min-content, auto));    /* Creates three equal height rows */
      gap: 10px; /* Adds 10px gap between rows and columns */
      grid-auto-rows: 50px;
    }

    .grid-item {
      padding: 5px;
      text-align: left;
      display: flex;
      flex-direction: column;

      div.label {
        display: flex;
        align-items: flex-end;

        font-size: 1.1rem;
        font-weight: 600;
        color: var(--vpsb-font-color-muted);
        min-height: 3.5rem;
      }

      div.value {
        display: flex;
        align-items: flex-start;
      }
    }
  }

  .locations {
    padding-left: 5px;
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--vpsb-font-color-muted);
  }
}

div.coupons-list {
  div.coupon-item {
    flex: 1 1 150px;
    min-width: 150px;
    max-width: 300px;
  }
}

