.vpsb.products {
  .content {

    p.lead {
    }

    .flex-text-image-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      &.reverse-wrap-direction {
        flex-wrap: wrap-reverse;
      }
    }

    .margin-right {
      margin-right: 30px;
    }

    .screenshot {
      background-color: #FFF;
      max-width: 300px;
      flex: 1 0 30%;
    }

    .text-block {
      flex: 0 1 60%;
    }
  }

  .product-desc-frame {
    padding: 0 1.3rem;
  }

  h2.fix-height-title {
    height: 150px;
    overflow-y: hidden;
  }

  hr.short-line {
    width: 40%;
    border: 1px solid;
  }

  .short-line-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .product-frame {
    height: 100%;
  }
}