.vpsb.bs4.admin {
  .form-control {
    font-size: 1.0rem;
  }

  &.admin_posts .preview {
    overflow-y: scroll;
    height: 750px;
    max-width: 840px;
    border: 1px solid #ccc;
    padding: 3px;
  }

  table {
    td.td-max-width-md {
      max-width: 14em;
      word-wrap: break-word;
    }
  }
}