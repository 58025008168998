.vpsb.hosters, .vpsb.compare_managed_vps {

  h3.short-vmargin {
    margin-top: 10px;
    font-size: 20px;
    display: inline-block;
  }

  .aligned-boxes-row {
    h2 {
      color: var(--vpsb-font-color-strong);
      font-size: 20px;
      font-weight: 600;
      margin-top: 10px;
      margin-bottom: 30px;
    }

    h4 {
      margin-top: 20px;
    }

    .fluid-columns {
      -webkit-column-width: 300px;
      -moz-column-width: 300px;
      column-width: 300px;

      -webkit-column-gap: 50px;
      -moz-column-gap: 50px;
      column-gap: 50px;

      .avoid-column-break {
        overflow: hidden; /* fix for Firefox */
        break-inside: avoid-column;
        -webkit-column-break-inside: avoid;
      }
    }

    #profile-show {
      margin-bottom: 10px;

      h3 {
        margin-top: 0px;
      }
    }

    .countries-fluid-columns {
      -webkit-column-width: 90px;
      -moz-column-width: 90px;
      column-width: 90px;

      -webkit-column-gap: 30px;
      -moz-column-gap: 30px;
      column-gap: 30px;
    }
  }

  a.plan-pill {
    margin-right: 0.3rem;

    &.private-plan {
      &.active {
        background-color: red;
        color: white;
      }

      &:not(active) {
        border: 1px solid red;
        color: red;
      }
    }

    &.public-plan {
      border: 1px solid color('primary');
    }
  }

  .btn-thumbnail {
    min-height: 220px;
    border: 1px solid rgb(172, 172, 172);
  }

  input.feature-checkbox {
    transform: scale(1.5);
    margin-left: 3px;
  }

  .hosters-in-columns, .checkboxes-in-columns {
    -webkit-columns: 3 200px;
    -moz-columns: 3 200px;
         columns: 3 200px;

    .column-item {
      display: inline-block;
      width: 100%;
      height: 85px;
      margin-bottom: 7px;

      -webkit-column-break-inside: avoid;
          page-break-inside: avoid;
               break-inside: avoid;

      .hoster-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        height: 100%;

        font-weight: 600;
        font-size: 18px;

        &.checked {
          background-color: var(--vpsb-primary-extra-muted);
          border-color: var(--vpsb-medium-gray);
          border-width: 2px;
        }
      }

      .hoster-frame {
        background-color: var(--vpsb-background-secondary);
        border-color: var(--vpsb-medium-gray);
        border-width: 1px;
        border-style: solid;
        border-radius: 5px;
        padding: 15px;
      }
    }
  }

  .hoster-name-with-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-weight: 600;
    font-size: 1.8rem;
  }

  .hoster-frames-row {
    .link-over-hoster-frame {
      text-decoration: none;
      color: var(--vpsb-font-color-strong);
    }

    .hoster-frame {
      height: 100%;
      padding: 0.8rem;
      min-height: 300px;
      max-width: 360px;

      table.attr-value-pairs {
        height: 100%;

        tr {
          td {
            padding: 1rem 0.4rem;
          }
        }
      }
    }
  }

  table.managed-hosters-list {
    tr.hoster-row {
      background-color: var(--vpsb-background-secondary);

      a.link-over-hoster-frame {
        text-decoration: none;
        color: var(--vpsb-font-color-strong);
      }
    }

    table.attr-value-pairs {
      width: 100%;

      td {
        padding-bottom: 0.7rem;
      }


      td:last-child {
        width: 60%;
      }
    }
  }

  label.lean-label {
    font-weight: 400;
    margin-left: 5px;
  }

  th span.plan-price {
    font-style: normal;
    font-weight: normal;
    font-size: 0.9rem;
  }

  td.filler-cell {
    background-color: #EEE;
  }

  .consistency-grade {
    font-size: 1.5rem;
    color: var(--vpsb-primary);
  }

  table.quick-links {
    width: 100%;
    background-color: var(--vpsb-background-secondary);
    color: var(--vpsb-font-color);

    tr {
      border-top: 1px solid darkgray;
      border-bottom: 1px solid darkgray;
    }

    td {
      padding: 10px;
    }
  }
}

.vpsb {
  .product-range-description {
    ul {
      margin-top: -0.5rem;
    }
  }
}
