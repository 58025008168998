.vpsb {

  .btn-primary {
    background-color: var(--vpsb-primary);
  }

  .btn-affiliate {
    background: #479b44;
    color: #FFF !important;

    &:hover, &:focus {
      background: #257922;
      color: #FFF !important;
    }
  }

  .btn-primary-over-white {
    color: var(--vpsb-primary) !important;
    background-color: white !important;
    border-color: var(--vpsb-primary);
    border-width: 2px;
    font-weight: 600;

    &.shaded-border {
      box-shadow: 1px 1px 6px rgba(13,13,18,.18);
      border-color: var(--vpsb-almost-white);
    }

    &:hover, &:focus {
      color: var(--vpsb-primary) !important;
      background-color: map-get($vpsb-colors, light-blue) !important;
      border-color: map-get($vpsb-colors, light-blue);
    }
  }

  .btn-vpsb-default {
    color: var(--vpsb-almost-white) !important;
    background-color: var(--vpsb-primary);
    border-color: var(--vpsb-primary);
    border-width: 1px;
    font-weight: 600;

    &.shaded-border {
      box-shadow: 1px 1px 6px rgba(13,13,18,.18);
    }

    &:hover, &:focus {
      color: var(--vpsb-almost-white) !important;
      background-color: var(--vpsb-primary);
    }

    html[data-theme="dark"] & {
      background-color: var(--vpsb-primary-muted);
      border-color: var(--vpsb-primary-medium-light);
      color: var(--vpsb-almost-white) !important;

      &.shaded-border {
        box-shadow: 1px 1px 6px rgba(255,255,255,.6);
      }

      &:hover, &:focus {
        color: var(--vpsb-almost-white) !important;
        background-color: var(--vpsb-primary);
        border-color: var(--vpsb-primary-muted);
      }
    }
  }

  .btn-vpsb-light {
    background-color: var(--vpsb-primary-extra-muted);
    border-color: var(--vpsb-primary-muted);
    border-width: 1px;
    color: var(--vpsb-primary) !important;
    font-weight: 600;

    html[data-theme="dark"] & {
      background-color: var(--vpsb-primary-medium-light);
      border-color: var(--vpsb-primary-medium);
      color: var(--vpsb-almost-white) !important;
    }
  }

  .btn-vpsb-strong {
    color: var(--vpsb-almost-white) !important;
    background-color: var(--vpsb-primary);
    border-color: var(--vpsb-primary-medium);
    border-width: 1px;
    font-weight: 600;

    &.shaded-border {
      box-shadow: 2px 2px 3px rgba(13,13,18,.18);
    }

    &:hover, &:focus {
      color: var(--vpsb-almost-white) !important;
      background-color: var(--vpsb-primary-medium-light);
    }

    html[data-theme="dark"] & {
      color: var(--vpsb-almost-white) !important;
      background-color: var(--vpsb-primary-medium-light);
      border-color: var(--vpsb-primary-medium);

      &.shaded-border {
        box-shadow: 2px 2px 3px rgba(255,255,255,.6);
      }

      &:hover, &:focus {
        color: var(--vpsb-light-gray) !important;
        background-color: var(--vpsb-primary);
        border-color: var(--vpsb-primary-muted);
      }
    }
  }



  .btn-no-border {
    color: var(--vpsb-primary);
    border-width: 0px;
  }

  .btn-thin {
    font-weight: 400;
    border-width: 1px;
  }

  .btn-xs {
    padding: 2px 6px;
    line-height: 1.5;
    border-radius: 3px;
    font-size: 1rem;
  }

  .btn-white-over-primary {
    color: white !important;
    background-color: var(--vpsb-primary);
    font-weight: 600;

    &:hover, &:focus {
      background-image: linear-gradient(rgb(0 0 0/15%) 0 0);
    }

    &.shaded-border {
      box-shadow: 1px 1px 6px rgba(255,255,255, 0.85);
      border-color: var(--vpsb-primary);
    }
  }

  .btn-white-over-secondary {
    color: white !important;
    background-color: var(--vpsb-secondary);
    font-weight: 600;

    &:hover, &:focus {
      background-image: linear-gradient(rgb(0 0 0/15%) 0 0);
    }
  }

  .btn {
    &.btn-bold {
      font-weight: bold;
    }

    &.btn-slim {
      font-weight: 400;
    }

    &.min-width-210 {
      min-width: 210px;
    }

    &.min-height-60 {
      min-height: 60px;
    }
  }

  .btn-row {
    .btn {
      margin-right: 1rem;
    }

    .btn:last-child {
      margin-right: 0rem;
    }
  }

  .btn-middle-text {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .btn-plain-link {
    background-color: transparent;
    border: none;
    color: var(--vpsb-primary);
    padding-left: 0px;
    padding-right: 0px;
    text-align: left;
    -moz-user-select: text;
    -webkit-user-select: text;
    user-select: text;
  }

  .button-wrapper .btn {
    margin-bottom: 5px;
  }

  .round-btn {
    width: 3rem;
    min-width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn-intelligence {
    background-color: var(--vpsb-secondary);
    background: linear-gradient(to right, #ff7e5f, #feb47b);
    border: 0;
    color: white;
  }
}
