body.vpsb.pdf_reports {
  &.verify, &.print {
    background-color: #FFF;
    color: black;

    h1,h2,h3,h4 {
      color: black;
    }

    table.table-grades, table.table-white {
      th {
        background-color: map-get($vpsb-colors, extra-light-blue);
        color: black;
      }

      td {
        background-color: white;
        color: black;
      }
    }

    a {
      color: map-get($vpsb-colors, main-blue);
    }
  }
}


.vpsb.bs4 {
  &.pdf_reports {

    .price-weighted-subtitle {
      font-size: 12px;
      font-style: oblique;
      color: #721009;
    }

    .report-summary-section {
      margin-top: 15px;

      ul {
        padding-left: 0px;
        margin-bottom: 5px;
        list-style-type: none;

        a {
          font-size: 12px;
        }
      }
    }

    .trash-icon {
      color: red;
      font-size: 10px;
    }

    &.print {
      @media print {
        .container {
          width: auto;
        }

        h2.page-break {
          break-before: page;
        }

        .avoid-break-inside {
          page-break-inside: avoid;
        }
      }
    }

    &.preview {
      background: url('data:image/svg+xml;utf8,<svg style="transform:rotate(-35deg); background-color: white;" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 60"><text x="0" y="25" fill="lightgrey">Preview</text></svg>') 0 0/100% 120vh;
    }
  }

  .color-dark-gray {
    color: var(--vpsb-dark-gray);
  }
}


